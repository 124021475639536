import React, { useState } from 'react';
import classnames from 'classnames';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

function Header({ children, className }) {
	const classes = classnames('text-xl', className);
	return <h2 className={classes}>{children}</h2>;
}

function Hero({ image }) {
	return (
		<div className="p-4 md:py-24 md:flex md:items-center">
			<div className="pb-8 md:w-1/2 md:p-8">
				<h1 className="text-2xl">
					Software that makes your painting estimates easy. More
					sales. Fewer mistakes. Fast results.
				</h1>
				<h2 className="my-4">
					Simple screens that anyone can learn quickly. Customizable
					to the way you do business.
				</h2>
			</div>
			<Img
				className="w-4/5 md:w-1/3 lg:w-1/5 mx-auto shadow-lg"
				fluid={image.childImageSharp.fluid}
				alt="Screenshot of Coose showing an estimate with some areas not included in the total."
			/>
		</div>
	);
}

let ctaCount = 0;
function CallToAction() {
	const emailId = `email-${ctaCount}`;
	const nameId = `name-${ctaCount}`;

	ctaCount = ctaCount + 1;

	function CtaInput({ id, name, ...props }) {
		return (
			<input
				className="bg-gray-100 border-b mr-2 text-xl px-3 py-2 focus:outline-none focus:bg-white w-full"
				id={id}
				name={name}
				{...props}
			/>
		);
	}

	function CtaLabel({ id, children }) {
		return (
			<label htmlFor={id} className="block text-xl">
				{children}
			</label>
		);
	}

	function CtaControl({ children }) {
		return <div className="mb-4">{children}</div>;
	}

	return (
		<form
			name="Email Collection"
			method="POST"
			data-netlify="true"
			action="/email-submitted"
			className="bg-gray-100 shadow-lg rounded-md mb-8"
		>
			<input type="hidden" name="form-name" value="Email Collection" />
			<div className="py-8 px-4 flex flex-col">
				<CtaControl>
					<CtaLabel id={nameId}>Your name</CtaLabel>
					<CtaInput id={nameId} name="name" />
				</CtaControl>
				<CtaControl>
					<CtaLabel id={emailId}>Your email address</CtaLabel>
					<CtaInput id={emailId} name="email" type="email" required />
				</CtaControl>
				<button className="rounded-md px-6 py-3 text-xl text-white bg-primary md:ml-4">
					I want to learn more →
				</button>
			</div>
		</form>
	);
}

function TrialLink() {
	return (
		<div className="bg-gray-100 shadow-lg rounded-md mb-8 py-8 px-4 text-center">
			<a
				href="https://app.coose.ca"
				className="inline-block rounded-md px-12 py-3 text-xl text-white bg-primary w-full sm:w-auto"
				onClick={() => {
					gtag('event', 'Clicked start trial');
				}}
			>
				Try it now →
			</a>
		</div>
	);
}

function Feature({ header, children, images, imageClasses, imageAlt }) {
	function Images() {
		return images.map((image) => (
			<Img
				key={image.name}
				fluid={image.childImageSharp.fluid}
				className={classnames(
					'w-4/5 md:w-1/3 lg:1/5 mx-auto shadow-lg',
					imageClasses
				)}
				alt={imageAlt}
			/>
		));
	}

	return (
		<div className="py-4 md:flex md:items-center">
			<div className="md:p-8 md:w-1/2">
				<Header>{header}</Header>
				<div className="py-4">{children}</div>
			</div>
			<Images />
		</div>
	);
}

function Features({ images }) {
	function imageFilter(key) {
		return function ({ name }) {
			return name.startsWith(key);
		};
	}

	function FeatureDivider() {
		return <hr className="my-8" />;
	}

	return (
		<div className="p-4">
			<h1 className="text-2xl" id="why-coose">
				Why should I pick Coose?
			</h1>
			<Feature
				header="It's Easy to Use"
				images={images.filter(imageFilter('easy-to-use'))}
				imageAlt="Screenshot of an area with walls entered."
			>
				Every screen is designed to be as simple as possible. We use
				industry standard best practices to be sure our software is
				intuitive.
			</Feature>
			<FeatureDivider />
			<Feature
				header="It's Flexible"
				images={images.filter(imageFilter('flexible'))}
				imageAlt="Screenshot of an estimate with a kitchen, exterior, and master bedroom. The master bedroom isn't included in the price."
			>
				<p className="pb-4">
					You can create different areas in your estimate in order to
					cater to your business.
				</p>
				<p className="pb-4">
					For example, you could make areas for a living room,
					bathroom, and outside. Or you could make two areas for a
					Living Room: Option A and Option B. Then let your customer
					pick which option they want.
				</p>
				<p>
					Anything you create in an estimate can be included or
					excluded for flexibility.
				</p>
			</Feature>
			<FeatureDivider />
			<Feature
				header="It's Customizable"
				images={images.filter(imageFilter('customizable'))}
				imageAlt="Screenshot showing an area template being created for exteriors."
			>
				<p className="pb-4">
					Coose is fully customizable. You can easily create line
					items for anything, from walls and ceilings to fences and
					siding.
				</p>
				<p>
					You can make reusable templates that let you group commonly
					used line items together.
				</p>
			</Feature>
			<FeatureDivider />
			<Feature
				header="Use Any Device"
				images={images.filter(imageFilter('works-on-any-device'))}
				imageClasses="shadow-none"
				imageAlt="Image of different device types."
			>
				<p className="pb-4">
					Coose runs on all of your devices. It's a web application so
					all it needs is a browser like Chrome or Safari. You can
					install it to your device for an enhanced experience.
				</p>
				<p>
					All your data is safely stored in the cloud so it'll be
					there no matter what device you use.
				</p>
			</Feature>
		</div>
	);
}

function Benefits() {
	return (
		<div className="p-4">
			<Header>Use Coose, and your business will see:</Header>
			<ul className="list-inside list-disc">
				<li>More sales</li>
				<li>Reduced training time</li>
				<li>More accurate estimates</li>
				<li>Quicker estimates</li>
			</ul>
		</div>
	);
}

function ChallengeForm() {
	const [shown, setShown] = useState(true);
	const [showThanks, setShowThanks] = useState(false);

	const formName = 'Estimating Challenges';

	return shown ? (
		<div className="fixed w-full bottom-0 bg-white p-6 border-gray-400 border-t md:border md:w-auto md:right-0 md:mr-1 md:mb-1">
			<button
				className="absolute right-0 top-0 text-2xl w-8 h-8"
				onClick={() => setShown(false)}
			>
				⨉
			</button>
			{showThanks ? (
				<p>Thanks for your submission!</p>
			) : (
				<form
					name={formName}
					data-netlify="true"
					method="POST"
					onSubmit={(e) => {
						e.preventDefault();

						const formData = new FormData(e.target);
						formData.append('form-name', formName);
						const data = new URLSearchParams(formData);
						fetch('/', {
							method: 'post',
							body: data,
						});
						setShowThanks(true);
						setTimeout(() => {
							setShown(false);
						}, 3000);
					}}
				>
					<h1>What challenges are you facing with estimating?</h1>
					<textarea
						className="border-gray-400 border w-full h-20 p-1"
						name="challenges"
					></textarea>
					<button className="w-full float-right md:w-auto rounded-md px-10 py-2 text-white bg-primary">
						Submit
					</button>
				</form>
			)}
		</div>
	) : null;
}

export default function Index() {
	const query = useStaticQuery(graphql`
		query HeroImageAndFeatureImagesQuery {
			heroImage: file(relativePath: { eq: "hero-image.png" }) {
				...fluidScreenshot
			}
			featuresImages: allFile(
				filter: { relativeDirectory: { eq: "features" } }
			) {
				nodes {
					name
					...fluidScreenshot
				}
			}
		}

		fragment fluidScreenshot on File {
			childImageSharp {
				fluid(maxWidth: 1079) {
					...GatsbyImageSharpFluid_withWebp_tracedSVG
				}
			}
		}
	`);

	return (
		<Layout>
			<SEO>
				<link rel="canonical" href="https://coose.ca" />
			</SEO>
			<Hero image={query.heroImage} />
			<TrialLink />
			<Features images={query.featuresImages.nodes} />
			<hr />
			<Benefits />
			<TrialLink />
			<ChallengeForm />
		</Layout>
	);
}
